.HowWeHelp {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20vh;
  height: 80vh;
}

.HowWeHelp-title {
  width: 40%;
}

.HowWeHelp-title h1 {
  font-size: 46px;
  width: 60%;
  text-transform: uppercase;
  font-weight: 800;
  margin-left: 25%;
  margin-top: 10%;
  color: rgb(20,60,90);
}

.HowWeHelp-title h1 .borderText {
  -webkit-text-stroke: 1px rgb(20,60,90);
  color: rgba(255,255,255,0)
}

.HowWeHelp-cases {
  width: 60%;
  position: relative;
}

.HowWeHelp-cases h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  width: 30%;
  position: relative;
  top: 15%;
  left: 8%;
  color: rgb(20,60,90);
}

.HowWeHelp-cases-card {
  width: 38%;
  height: 200px;
  border: 2px white solid;
  box-shadow: 5px 5px rgba(20,60,96,0.5);
  background-image: radial-gradient( circle at 100% 0% , #3f7c9b, #387594, #306e8e, #296787, #216081, #1e597a, #1a5273, #184262, #183959, #17304f, #162845) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.HowWeHelp-cases-card-number {
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 600;
  font-size: 18px;
}

.HowWeHelp-cases-card-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
}

.HowWeHelp-cases-card-image img {
  width: 100%;
}

.HowWeHelp-cases-card-title {
  position: relative;
  top: 15%;
  font-weight: 600;
  font-size: 18px;
  margin-right: 30px;
  margin-left: 30px;
}

.HowWeHelp-cases-card-text {
  position: relative;
  font-size: 14px;
  top: 17%;
  margin-right: 30px;
  margin-left: 30px;
}

#desirability {
  position: absolute;
  top: 15%;
  right: 12%;
}

#desirability .HowWeHelp-cases-card-title  {
  top: 15%;
}

#desirability .HowWeHelp-cases-card-text  {
  top: 17%;
}

#feasibility {
  position: absolute;
  top: 40%;
  left: 5%;
}

#invest {
  position: absolute;
  top: 65%;
  right: 15%;
}



/* Laptop -------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .HowWeHelp-title h1 {
    font-size: 36px;
  }
  
  .HowWeHelp-cases h2 {
    font-size: 20px;
  }

  .HowWeHelp-cases-card {
    width: 38%;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .HowWeHelp-cases-card-text {
    font-size: 13px;
    margin-top: 10px;
  }

  .HowWeHelp-cases-card-title {
    font-size: 16px;
  }

  #invest {
    position: absolute;
    top: 70%;
    right: 15%;
  }

}

/* Tablet wide -------------------------------------------------------------------------------*/
@media all and (max-width: 1500px) {

  .HowWeHelp-cases-card {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

/* Tablet -------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {

  .HowWeHelp-title h1 {
    font-size: 32px;
  }

  .HowWeHelp-cases h2 {
    font-size: 16px;
  }

  .HowWeHelp-cases-card {
    width: 38%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .HowWeHelp-cases-card-title {
    font-size: 14px;
  }

  .HowWeHelp-cases-card-text {
    font-size: 11px;
    margin-top: 10px;
  }

  #invest {
    position: absolute;
    top: 70%;
    right: 15%;
  }

}

/* Mobile -------------------------------------------------------------------------------*/

@media all and (max-width: 900px) {

  .HowWeHelp {
    height: auto;
  }

  .HowWeHelp-title {
    width: 80%;
    margin-left: 10%;
  }

  .HowWeHelp-title h1 {
    font-size: 36px;
    margin-left: 0%;
    margin-top: 20%;
    justify-content: center;
  }

  .HowWeHelp-cases h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    margin-left:10%;
    margin-top: 60px;
    margin-bottom: 20px;
    position: static;
  }

  .HowWeHelp-cases {
    width: 100%;
  }

  .HowWeHelp-cases-card {
    width: 80%;
    height: auto;
    margin-left: 10%;
    background: rgba(255,255,255,0);
    border: 2px solid rgb(20,60,90);
    background-image: none !important;
  }

  .HowWeHelp-cases-card-number {
    color: rgb(20,60,90);
  }

  .HowWeHelp-cases-card-image {
    display: none;
  }

  .HowWeHelp-cases-card-title {
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 10px;
    color: rgb(20,60,90);
  }

  .HowWeHelp-cases-card-text {
    font-size: 12px;
    color: black;
  }

  #desirability, #feasibility, #invest {
    position: relative;
    top: 0px;
    left:0px;
    right: 0px;
    bottom: 0px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  
}

