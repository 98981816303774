/* Introduction Section - Basic Styling ------------------------------------------*/
.OurCapabilities {
  display: flex;
  flex-wrap: wrap;
  background: url("../assets/backgroundDesktopEmpty.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 200px;
  margin-bottom: 100px;
}

.OurCapabilities-title {
  width: 80%;
  margin-left: 10%;
  margin-top: 100px;
  margin-bottom: 70px;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 800;
  color: white;
  text-align: center;
}

.OurCapabilities-title .borderText {
  -webkit-text-stroke: 1px white;
  color: rgba(255,255,255,0);
  margin-right: 20px;
}

.OurCapabilities-cards {
  width: 80%;
  min-height: 350px;
  margin-bottom: 100px;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.OurCapabilities-cards-card {
  width: 22%;
  padding-right: 15px;
  height: 100%;
  color: white;
  border-right: 1px solid white;
}

div.OurCapabilities-cards-card-number h1 {
  font-size: 100px;
  font-weight: 800;
  margin-bottom: 10px;
  -webkit-text-stroke: 2px white;
  color: rgba(255,255,255,0);
  margin-right: 10px;
}

h1.OurCapabilities-cards-card-title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

p.OurCapabilities-cards-card-text {
  font-size: 14px;
  margin-bottom: 10px;
}



/* Laptop -------------------------------------------------------------------------------*/
@media all and (max-width: 1500px) {

  .OurCapabilities-title {
    font-size: 36px;
  }

  .OurCapabilities-cards-card {
    font-size: 14px;
  }

  .OurCapabilities-cards-card-imageCircle {
    width: 80px;
    height: 80px;
  }
}

/*mobile phone ----------------------------------------------------------*/
@media all and (max-width: 800px) {
  
  .OurCapabilities {
    display: none;
    height: auto;
  }

  .OurCapabilities-title {
    font-size: 36px;
    margin-top: 40px;
    height: auto;
  }

  .OurCapabilities-cards {
    justify-content: space-around;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .OurCapabilities-cards-card {
    width: 40%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .OurCapabilities-cards-card-imageCircle {
    width: 70px;
    height: 70px;
  }
}


/* Samsung fold ----------------------*/
@media all and (max-width: 290px) {
  
}

/* small-phone ---------------------------*/
@media all and (max-height: 700px) {
  
}
