div.MobileCapabilityCarousel {
    display: none;
}

/*mobile phone ----------------------------------------------------------*/
@media all and (max-width: 800px) {
div.MobileCapabilityCarousel {
    background-image: radial-gradient( circle at 50% 40% , #3f7c9b, #387594, #306e8e, #296787, #216081, #1e597a, #1a5273, #184262, #183959, #17304f, #162845) !important;
    width: 100%;
    height: auto;
    display: block;
    padding-top: 100px;
    padding-bottom: 100px;
}

h1.MobileCapabilityCarousel-title {
    width: 80%;
    margin-left: 10%;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 800;
    color: white;
    text-align: center;
  }
  
  h1.MobileCapabilityCarousel-title span.borderText {
    -webkit-text-stroke: 1px white;
    color: rgba(255,255,255,0);
  }

div.MobileCapabilityCarousel-card {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: center;
    color: white;
}

h1.MobileCapabilityCarousel-card-number {
    font-size: 100px;
    font-weight: 800;
    margin-bottom: 10px;
    -webkit-text-stroke: 2px white;
    color: rgba(255,255,255,0);
    margin-right: 10px;
  }
  
  h1.MobileCapabilityCarousel-card-title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
  }
  
  p.MobileCapabilityCarousel-card-text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .MobileCapabilityCarousel-progress {
    position: relative;
  }

  p.MobileCapabilityCarousel-card-text-swipe {
    margin-top: 40px;
    font-size: 12px;
    font-style: italic;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
    color: white;
  }

  div.MobileCapabilityCarousel-progressDots{
    display: flex;
    justify-content: center;
    align-items: center;
}

  div.MobileCapabilityCarousel-progressDots-dotActive {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin: 5px;
    background-color: white;
    border:1px solid white;
  }
  div.MobileCapabilityCarousel-progressDots-dotInActive {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0);
    border:1px solid white;
  }
}