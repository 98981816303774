.MarblesTile {
  width: 25vw;
  height: 25vw;
  margin-bottom: 2.5vw;
}

#MonthlyScent {
  background: url("../assets/MonthlyScent2.png");
  background-size: cover;
  background-position: center;
}

#SongRqst {
  background: url("../assets/SongRqst.png");
  background-size: cover;
  background-position: center;
}

#StandZero {
  background: url("../assets/StandZero.png");
  background-size: cover;
  background-position: center;
}

#KikoFood {
  background: url("../assets/KikoFood.png");
  background-size: cover;
  background-position: center;
}

#RosieDrinks {
  background: url("../assets/RosieDrinks.png");
  background-size: cover;
  background-position: center;
}

#ReadyPlayerMe {
  background: url("../assets/ReadyPlayerMe.png");
  background-size: cover;
  background-position: center;
}

#RebornGames {
  background: url("../assets/Reborn.png");
  background-size: cover;
  background-position: center;
}

#Brilliants {
  background: url("../assets/Brilliants.png");
  background-size: cover;
  background-position: center;
}

#LilaLinen {
  background: url("../assets/LilaLinen.png");
  background-size: cover;
  background-position: center;
}

#Captic {
  background: url("../assets/Captic.png");
  background-size: cover;
  background-position: center;
}

.MarblesTile-overlay {
  width: 100%;
  height: 100%;
  position: relative;
}

.MarblesTile-overlay-content {
  position: absolute;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20px;
  bottom: 10%;
  width: 80%;
  height: 44%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.MarblesTile-overlay-content.hoverActive {
  position: absolute;
  margin-left: 5%;
  margin-right: 5%;
  bottom: 10%;
  width: 90%;
  height: 50%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.MarblesTile-overlay-content h1 {
  width: 80%;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.MarblesTile-overlay-content p {
  width: 80%;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.MarblesTile-overlay-content p.casehover {
  color: grey;
}

.MarblesTile-overlay-content p.case {
  color: grey;
  display: none;
}

/* Mobile -------------------------------------------------------------------------------*/
@media all and (max-width: 900px) {
  .MarblesTile {
    width: 70vw;
    height: 70vw;
    margin-bottom: 40px;
  }
  .MarblesTile-overlay {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .MarblesTile-overlay-content p {
    font-size: 12px;
  }
}
