.Marbles {
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.Marbles-title {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50px;
  margin-top: 100px;
  text-align: center;
  letter-spacing: 2px;
}

.Marbles-title h1 {
  color: rgb(20,60,90);
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 30px;
}

.Marbles-title h1 .borderText {
  -webkit-text-stroke: 1px rgb(20,60,90);
  color: rgba(255,255,255,0);
  margin-right: 20px;
}

.Marbles-title p {
  width: 90%;
  margin: 0px auto;
}

.Marbles-tiles {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
}

.Marbles-tiles a {
  color: black;
}

.Marbles-tiles a.inactiveLink {
  pointer-events: none;
}

.Marbles-overlay-content-button {
  background-color: rgba(0, 0, 0, 0);
  padding: 8px 20px;
  margin-top: 20px;
  border: black 1px solid;
  color: black;
  letter-spacing: 2px;
}

.Marbles-overlay-content-button:hover {
  background-color: black;
  padding: 8px 20px;
  margin-top: 20px;
  color: white;
  letter-spacing: 2px;
  cursor: pointer;
}

a.emailLink {
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
}

/* Laptop -------------------------------------------------------------------------------*/
@media all and (max-width: 1500px) {
  
  .Marbles-title h1 {
    font-size: 36px;
  }
  .Marbles-title p {
    width: 100%;
    margin: 0px auto;
  }
}

/* Mobile -------------------------------------------------------------------------------*/
@media all and (max-width: 900px) {
  
  .Marbles-title h1 {
    font-size: 30px;
  }
  
  .Marbles-tiles {
    justify-content: center;
  }
}
