/* Large Screen -------------------------------------------------------------------------------*/
.Introduction {
  background-color: rgb(18,75,100);
  background-image: radial-gradient(circle at 75% 50%,
    #3f7c9b, #387594, #306e8e, #296787, #216081, #1e597a, #1a5273, #174b6c, #184262, #183959, #17304f, #162845) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  min-height: 115vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
}

.Introduction-backgroundOverlayDark3DEffect {
  display: none;
  position: absolute;
  background-color: rgba(0,0,0,0.1);
  width: 100%;
  height: 50%;
  top: 50%;
  z-index: 0;
}

.Introduction-overlay-text {
  width: 50%;
  height: 100%;
  z-index: 1;
}

.Introduction-overlay-text-content {
  width: 95%;
  margin-left: 20%;
  margin-right: 5%;
  color: white;
}

.Introduction-overlay-text-content h1 {
  font-size: 40px;
  font-weight: 800;
  width: 80%;
  margin-top: 20px;
}

.Introduction-overlay-text-content p {
  font-size: 15px;
  width: 80%;
}


.Introduction-overlay-image {
  width: 50%;
  height: 100%;
  z-index: 1;
}

.Introduction-overlay-image img {
  width: 60%;
  margin-left: 20%;
}

.astronautSmall {
  display: none;
}

.astronautLarge {
  display: flex;
}

/* Laptop -------------------------------------------------------------------------------*/
@media all and (max-width: 1600px) {
  .Introduction {
    background-color: rgb(18,75,100);
    background-image: radial-gradient(circle at 75% 50%,
    #3f7c9b, #387594, #306e8e, #296787, #216081, #1e597a, #1a5273, #174b6c, #184262, #183959, #17304f, #162845) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 115vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .Introduction-overlay-text {
    width: 50%;
    height: 100%;
  }

  .Introduction-overlay-text-content {
    width: 75%;
    margin-left: 20%;
    margin-right: 5%;
    color: white;
  }

  .Introduction-overlay-text-content h1 {
    font-size: 38px;
    font-weight: 800;
    width: 90%;
    margin-top: 20px;
  }

  .Introduction-overlay-text-content p {
    font-size: 14px;
    width: 100%;
  }

  .Introduction-overlay-image {
    width: 50%;
    height: 100%;
  }

  .Introduction-overlay-image img {
    width: 60%;
    margin-left: 20%;
  }
}

/*mobile phone ----------------------------------------------------------*/
@media all and (max-width: 800px) {
  .Introduction {
    background-color: rgb(18,75,100);
    background-image: radial-gradient( circle at 50% 40% , #3f7c9b, #387594, #306e8e, #296787, #216081, #1e597a, #1a5273, #184262, #183959, #17304f, #162845) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .Introduction-overlay-text {
    width: 100%;
    order: 2;
  }

  .Introduction-overlay-text-content {
    width: 80%;
    margin: 10%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    bottom: 80px;
  }

  .Introduction-overlay-text-content h1 {
    width: 100%;
    text-align: center;
    position: relative;
    top: -20px;
    font-size: 30px;
    z-index: 1000;
  }

  .Introduction-overlay-text-content p {
    width: 100%;
    text-align: center;
  }

  .Introduction-buttons {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .Introduction-buttons-ourWorkButton {
    margin-left: 0px;
  }

  .Introduction-overlay-image {
    width: 100%;
    order: 1;
    position: relative;
    top: 60px;
  }

  .astronautSmall {
    display: flex;
  }

  .astronautLarge {
    display: none;
  }
}

/* Samsung fold ----------------------*/
@media all and (max-width: 290px) {
 
}

/* iPhone SE ----------------------*/
@media all and (max-width: 500px) and (max-height:750px) {

  .Introduction-overlay-image {
    top: 50px;
    width: 80%;
    margin: auto;
  }

  .Introduction-overlay-text-content h1 {
    font-size: 26px;
    top: 0px;
  }

  .titleIntroBreak {
    display: none;
  }

  .Introduction-overlay-text-content p {
    font-size: 12px;
    position: relative;
    top: 5px;
  }
 
}

/* small-phone ---------------------------*/
@media all and (max-height: 700px), all and (max-width: 800px) {
 
}
