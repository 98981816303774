.Homepage {
  max-width: 100%;
}

#navigationBar {
  z-index: 1000 !important;
}

.introductionPage{
  z-index: -1000 !important
}
