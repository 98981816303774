.Promises {
  background: url("../assets/backgroundDesktopEmpty.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Promises-intro {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: 60px;
  padding-top: 120px;
}

.Promises-intro h1 {
  color: white;
  width: 100%;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 20px;
}

.Promises-intro h1 {
  color: white;
  width: 100%;
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 30px;
}

.Promises-intro h1 .borderText {
  -webkit-text-stroke: 1px white;
  color: rgba(255,255,255,0);
  margin-right: 20px;
}

.Promises-intro p {
  color: white;
  font-weight: 400;
  letter-spacing: 1.5px;
  width: 100%;
  text-align: center;
}

.Promises-overview {
  display: flex;
  align-content: center;
  text-align: center;
  padding: 10% 10%;
}

.Promises-overview-card {
  width: 33%;
  margin-bottom:60px !important;
}

.Promises-overview-card img {
  width: 80%;
}

.Promises-overview-card h4 {
  border: solid 2.5px;
  margin: 15px 15px;
  padding: 7px;
  font-size: 16px;
  color: white;
  letter-spacing: 3px;
}

.Promises-overview-card p {
  font-size: 14px;
  margin: 15px 15px;
  color: white;
  letter-spacing: 1.5px;
}

.Promises-footer {
  position: relative;
  bottom: 15px;
  font-size: 12px;
  color: white;
  width: 100%;
  text-align: center;
  letter-spacing: 1.5px;
}

/* Laptop ------------------*/
@media all and (max-width: 1500px) {

  .Promises-intro h1 {
    font-size: 36px;
  }
  
}

/* Mobile ------------------*/
@media all and (max-width: 900px) {
  .Promises {
    background: url("../assets/backgroundMobileEmpty.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .Promises-overview {
    display: block;
  }
  .Promises-overview-card {
    width: 100%;
    margin: 5% 0%;
  }

  .Promises-intro h1 {
    font-size: 36px;
  }
}
