.Pricing {
  position: relative;
}

.Pricing-textbox {
  text-align: center;
  padding-top: 100px;
  position: relative;
  top: -100px;
  margin: 0px 30px;
}

.Pricing-textbox p {
  line-height: 1.9;
  letter-spacing: 3px;
}


.Pricing-textbox h1 {
  color: rgb(20,60,90);
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 800;
  margin-bottom: 30px;
}

.Pricing-textbox h1 .borderText {
  -webkit-text-stroke: 1px rgb(20,60,90);
  color: rgba(255,255,255,0);
  margin-right: 20px;
}

.Pricing-button {
  background-color: rgba(0, 0, 0, 0);
  padding: 8px 20px;
  margin-top: 20px;
  border: black 1px solid;
  color: black;
  letter-spacing: 2px;
}

.Pricing-button:hover {
  background-color: rgba(255, 42, 127, 1);
  padding: 8px 20px;
  margin-top: 20px;
  border: rgba(255, 42, 127, 1) 1px solid;
  color: white;
  letter-spacing: 2px;
}

.Pricing-conditions {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Pricing-conditions-card {
  background-color: rgb(250,250,250);
  border-radius: 10px;
  height: 150px;
  margin-bottom: 10px;
  margin-right: 20px;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.Pricing-conditions-card-text {
  width: 100%;
  text-align: center;
  letter-spacing: 1.5px;
}
.Pricing-conditions-card-number {
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: 1.5px;
}

/* Desktop -------------------------------------------------------------------------------*/
@media all and (min-width: 1000px) {
  .Pricing-textbox {
    margin: 10% 22%;
  }
  .Pricing-textbox h1 {
    font-size: 36px;
  }
}

/* Mobile -------------------------------------------------------------------------------*/

@media all and (max-width: 900px) {
  .Pricing-conditions {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .Pricing-conditions-card {
    width: 100%;
    margin-top: 20px;
  }
  .Pricing-textbox h1 {
    font-size: 36px;
    margin-top: 200px;
  }
}

