.ContactForm-form.hidden {
  display: none;
}

.ContactForm-contactButton.hidden {
  display: none;
}

.ContactForm-form {
  margin-top: 30px;
  height: 200px;
  background-color: rgba(23, 18, 47, 0);
  width: 100%;
  padding: 8% 5% 10% 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ContactForm-form div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.ContactForm-form-label label {
  font-weight:600;
}

.ContactForm-form .ContactForm-form-input {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.ContactForm-form input {
  color: black;
}

.ContactForm-form p {
  font-size: 12px;
}

.ContactForm-introductionPage-buttons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
}

.ContactForm-introductionPage-buttons-ourWorkButton, .ContactForm-introductionPage-buttons-contactButton, .ContactForm-form-submitButton {
  background-color: rgba(0, 0, 0, 0);
  border: white 1px solid;
  padding: 8px 20px;
  margin-top: 20px;
  margin-right: 20px;
  box-shadow: turquoise 0px 0px 10px;
  color: white;
  letter-spacing: 2px;
}

.ContactForm-introductionPage-buttons-ourWorkButton:hover,.ContactForm-introductionPage-buttons-contactButton:hover, .ContactForm-form-submitButton:hover {
  background-color: rgb(84, 217, 202);
  border: rgb(84, 217, 202) 1px solid;
  color: white;
  letter-spacing: 2px;
  cursor: pointer;
}

.ContactForm-form input {
  border: white 1px solid;
  box-shadow: turquoise 0px 0px 10px;
  margin-right: 10px;
  padding: 8px 20px;
  width: 50%;
  margin-top: 20px;
}

.EmailSubmittedText {
  margin-top: 40px;
}

a {
  all: unset;
}

a:hover {
  all: unset;
}

/* Desktop ------------------------------*/
@media all and (min-width: 1000px) {
  .ContactForm-form {
    background-color: rgba(23, 18, 47, 0);
    padding: 0px;
    justify-content: left !important;
  }
  .ContactForm-form div {
    justify-content: left !important;
  }
}

/*mobile phone ----------------------------------------------------------*/
@media all and (max-width: 800px) {

  .ContactForm-introductionPage-buttons {
    justify-content: center;
    width: 300px;
    margin: 0 auto;
  }
  .ContactForm-introductionPage-buttons-ourWorkButton, .ContactForm-introductionPage-buttons-contactButton {
    width: 80%;
    margin: 40px auto;
    margin-top: 40px;
  }

  .ContactForm-introductionPage-buttons-ourWorkButton {
    display: none;
  }



  .ContactForm-form input {
    width: 90%;
  }

  .ContactForm-form {
    justify-content: center !important;
  }
}
