/* Navigation menu - Basic Styling ------------------------------------------*/
.Navigation {
  position: absolute;
  top: 10px;
  width: 100%;
  box-sizing: border-box;
  height: 8vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000 !important;
}

.Navigation ul {
  list-style-type: none;
}

.Navigation a {
  color: black;
  text-decoration: none;
}
.Navigation a:hover {
  font-weight: 600;
}

.Navigation .menu {
  display: flex;
}

.Navigation .menu.active {
  background-color: rgb(21, 63, 91);
}

.Navigation menu .logo {
  display: flex;
}

.Navigation img.brandLogo {
  width: 90%;
  max-width: 80px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.Navigation .menu li {
  font-size: 12px;
  padding: 15px 5px;
  white-space: nowrap;
}

.Navigation .logo a,
.Navigation .toggle a {
  font-size: 20px;
}

.Navigation .item a {
  color: white;
}

/* Mobile -------------------------------------------------------------------*/

/* Navigation Menu*/

.Navigation .menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.Navigation img.brandLogo {
  width: 90%;
  max-width: 80px;
  position: absolute;
  left: 20px;
  top: 30px;
}

.Navigation .toggle {
  order: 1;
  text-align: right;
  position: absolute;
  right: 20px;
  top: 10px;
}

.Navigation .toggleBars {
  color: white;
  font-size: 20px;
}

.Navigation .item.button {
  order: 2;
}
.Navigation .menu .item {
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
}

.Navigation .menu .item.active {
  display: block;
  background: rgb(21, 63, 91);
}

.Navigation .item.active a {
  color: white;
}

/* Tablet ---------------------------------------------------------------------------*/
@media all and (min-width: 600px) {
  /*Navigation Menu*/

  .Navigation .logo {
    flex: 1;
  }

  .Navigation .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
  }

  .Navigation .item.button {
    width: auto;
    order: 1;
    display: block;
  }
}

/* Desktop -------------------------------------------------------------------------------*/
@media all and (min-width: 800px) {
  /* Navigation Menu */

  .Navigation img.brandLogo {
    width: 90%;
    max-width: 90px;
    position: absolute;
    left: 50px;
    top: 25px;
  }

  .Navigation .menu .item {
    display: block;
    width: auto;
  }

  .Navigation .toggle {
    display: none;
  }

  .Navigation .logo {
    order: 0;
  }
  .Navigation .item {
    order: 1;
  }

  .Navigation .item a {
    font-weight: 450;
  }

  .Navigation .item a:hover {
    color: white;
    font-weight: 700;
    text-decoration: none;
  }

  .Navigation .menu li {
    padding: 0px 20px;
    font-size: 14px;
    margin-top: 20px;
  }

  .Navigation .menu {
    padding: 0px 50px;
    align-items: center;
  }
}
